<script setup>

const props = defineProps({
  car: Object
})

const defaultColor = '#008AA9';
const colors = {
  'portal_wyprzedaz': '#A80041',
  'portal_wysokirabat': '#8C0AA8',
  'portal_wyposazenie': '#A8A80A',
  'portal_lpg': '#378F10',
  'portal_bestseller': '#E75B1D'
}

const ignoredLabels = [
  'portal_first',
  'portal_top',
];

const carDefs = ref([
  {brand: 'KIA', model: 'Sportage', body: 'SUV', url: '/wybierz/kia/sportage'},
  {brand: 'Nissan', model: 'Qashqai', body: 'SUV', url: '/wybierz/nissan/qashqai'},
  {brand: 'Audi', model: 'A4', body: 'sedan', url: '/wybierz/audi/a4'},
  {brand: 'Renault', model: 'Master', body: 'Furgon', url: '/wybierz/renault/master'},
  {brand: 'Opel', model: 'Mokka', body: 'hatchback', url: '/wybierz/opel/mokka'},
  {brand: 'Hyundai', model: 'Tucson', body: 'SUV', url: '/wybierz/hyundai/tucson'},
]);

const fromAds = computed(() => carDefs.value.find(c => c.model === props.car.modelName && (props.car?.state === 'Nowy' || !props.car?.state)));

const availableLabels = computed(() => {
  if (!props.car || !Array.isArray(props.car.labels)) return [];
  return props.car.labels.filter(i => {
    return i.code.toLowerCase().startsWith("portal_") &&
      !i.code.toLowerCase().startsWith("portal_hidden_") &&
      ignoredLabels.indexOf(i.code.toLowerCase()) === -1
  });
})

</script>

<template>
  <div class="flex gap-2 absolute bottom-0 left-0 right-0 px-4 py-2">
    <template v-for="label in availableLabels">
      <CarTileLabel :color="colors[label.code.toLowerCase()] || defaultColor">{{ label.name }}</CarTileLabel>
    </template>
    <CarTileLabel v-if="!!fromAds" color="#FFCC00" textColor="#000000">Z reklamy radiowej</CarTileLabel>
  </div>
</template>
